import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import GlobeIcon from '../../../../assets/icons/Globe';
import useStyles from './LocalePickerModal.styles';

function LocalePickerModal({
  languageOptions,
  currentCountry,
  currentLocale,
  open,
  countryLocalesMap,
  onClose,
  onSelectLocale
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedLocale, setSelectedLocale] = useState('');

  useEffect(() => {
    setSelectedCountry(currentCountry);
  }, [currentCountry]);

  useEffect(() => {
    setSelectedLocale(currentLocale);
  }, [currentLocale]);

  const onSelectCountry = (e) => {
    const { value } = e.target;

    setSelectedCountry(value);

    if (value in countryLocalesMap) {
      setSelectedLocale(countryLocalesMap[value][0].locale);
    }
  };

  const selectLocale = (locale) => () => {
    setSelectedLocale(locale);
  };

  const confirmLocaleSelection = (e) => {
    e.preventDefault();

    onSelectLocale(selectedCountry, selectedLocale);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Button
        type="button"
        className={classes.closeButton}
        onClick={onClose}
        aria-label={t('common:countrySelectorCloseModalAriaLabel')}
      >
        x
      </Button>
      <form onSubmit={confirmLocaleSelection}>
        <Box
          aria-live="polite"
          role="presentation"
          aria-label={t('common:localePickerAriaLabel')}
          paddingTop={6}
          paddingLeft={2}
          paddingRight={2}
          paddingBottom={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <div role="menuitem" aria-label={t('common:countrySelectorTitle')}>
            <Box display="flex" mb={2}>
              <GlobeIcon />
              <Typography variant="h6" className={`h6 ${classes.title}`}>
                {t('common:countrySelectorTitle')}
              </Typography>
            </Box>
            <Select
              native
              className={classes.countrySelect}
              value={selectedCountry}
              onChange={onSelectCountry}
            >
              {languageOptions?.map((languageOption) => (
                <optgroup key={languageOption.regionName} label={languageOption.regionName}>
                  {languageOption.countries?.map((country) => (
                    <option key={country.country} value={country.country}>
                      {country.country}
                    </option>
                  ))}
                </optgroup>
              ))}
            </Select>
          </div>
          {selectedCountry && selectedCountry in countryLocalesMap && (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h6" className={`h6 ${classes.confirmTitle}`}>
                {t('common:countrySelectorChooseLanguage')}
              </Typography>
              <Box display="flex" justifyContent="space-between">
                {countryLocalesMap[selectedCountry].map((locale) => {
                  return (
                    <Button
                      key={locale.locale}
                      aria-selected={selectedLocale === locale.locale ? 'true' : 'false'}
                      aria-label={
                        selectedLocale === locale.locale
                          ? `Selected ${locale.title}`
                          : `Press enter to select ${locale.title}`
                      }
                      type="button"
                      onClick={selectLocale(locale.locale)}
                      className={cx(classes.localeButton, {
                        [classes.selectedLocaleButton]: selectedLocale === locale.locale
                      })}
                    >
                      <span lang={locale.locale}>{locale.title}</span>
                    </Button>
                  );
                })}
              </Box>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submitButton}
              >
                {t('common:countrySelectorContinueButton')}
              </Button>
            </Box>
          )}
        </Box>
      </form>
    </Dialog>
  );
}

LocalePickerModal.propTypes = {
  languageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      regionName: PropTypes.string.isRequired,
      countries: PropTypes.arrayOf(
        PropTypes.shape({
          country: PropTypes.string.isRequired,
          locales: PropTypes.arrayOf(
            PropTypes.shape({
              locale: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired
            })
          ).isRequired
        })
      )
    })
  ).isRequired,
  currentCountry: PropTypes.string,
  currentLocale: PropTypes.string,
  countryLocalesMap: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        locale: PropTypes.string.isRequired
      })
    )
  ).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelectLocale: PropTypes.func.isRequired
};

LocalePickerModal.defaultProps = {
  currentCountry: '',
  currentLocale: ''
};

export default LocalePickerModal;
