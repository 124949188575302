import React from 'react';
import PropTypes from 'prop-types';

export const FacebookIconPropTypes = {
  className: PropTypes.string
};

function FacebookIcon({ className }) {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      className={className}
      version="1.1"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <title>Facebook</title>
      <path d="M32 2v28q0 0.813-0.594 1.406t-1.406 0.594h-8v-12.438h4l0.672-4.609h-4.672v-3.625q0-1 0.508-1.531t1.492-0.531h3v-4.156q-1.344-0.219-3.281-0.219-3.219 0-4.914 1.633t-1.695 4.445v3.984h-4v4.609h4v12.438h-15.109q-0.813 0-1.406-0.594t-0.594-1.406v-28q0-0.813 0.594-1.406t1.406-0.594h28q0.813 0 1.406 0.594t0.594 1.406z" />
    </svg>
  );
}

FacebookIcon.propTypes = FacebookIconPropTypes;

FacebookIcon.defaultProps = {
  className: null
};

export default FacebookIcon;
