const languageMapping = {
  'ca': 'en-CA',
  'fr-ca': 'fr-CA',
  'me-en': 'en-AE',
  'nz-en': 'en-NZ',
  'au-en': 'en-AU',
  'gb-en': 'en-GB',
  'hk': 'zh-Hant-HK',
  'hk-en': 'en-HK',
  'en-US': 'en-US',
  'cn': 'zh-CN',
  'cn-en': 'en-ZW'
};

const getLang = (lang) => {
  return languageMapping[lang] || lang;
};

export default getLang;
