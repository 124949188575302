import React from 'react';
import PropTypes from 'prop-types';

export const LinkedinIconPropTypes = {
  className: PropTypes.string
};

function LinkedinIcon({ className }) {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      className={className}
      version="1.1"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <title>Linkedin</title>
      <path d="M0.672 11.328h6.656v20.672h-6.656v-20.672zM18 32v-12q0-1.922 1.055-2.961t2.648-1.039q1.609 0 2.617 1.148t1.008 3.523v11.328h6.672v-12q0-4.406-2.203-6.867t-5.797-2.461q-1.906 0-3.422 0.953t-2.578 2.563l-0.219-2.859h-6.563l0.109 4.672v16h6.672zM3.969 8h-0.047q-1.734 0-2.828-1.055t-1.094-2.617q0-1.578 1.117-2.617t2.914-1.039q1.766 0 2.852 1.039t1.117 2.617q0 1.578-1.109 2.625t-2.922 1.047z" />
    </svg>
  );
}

LinkedinIcon.propTypes = LinkedinIconPropTypes;

LinkedinIcon.defaultProps = {
  className: null
};

export default LinkedinIcon;
