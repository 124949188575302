import React from 'react';
import PropTypes from 'prop-types';

export const TwitterIconPropTypes = {
  className: PropTypes.string
};

function TwitterIcon({ className }) {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      className={className}
      version="1.1"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <title>Twitter</title>
      <path d="M32 5.734q-1.313 1.984-3.266 3.406v0.859q0 2.188-0.555 4.398t-1.594 4.297-2.664 3.898-3.641 3.164-4.648 2.133-5.555 0.781q-5.922 0-10.078-2.672 0.719 0.109 1.563 0.109 2.188 0 4.281-0.844t3.859-2.266q-2.109-0.016-3.805-1.305t-2.305-3.258q0.656 0.125 1.219 0.125 0.906 0 1.75-0.234-2.266-0.453-3.766-2.258t-1.5-4.18v-0.078q1.406 0.766 2.969 0.813-1.359-0.906-2.148-2.344t-0.789-3.094q0-1.609 0.891-3.188 2.469 3 5.969 4.758t7.547 1.977q-0.141-0.609-0.141-1.516 0-2.719 1.914-4.633t4.633-1.914q2.797 0 4.828 2.063 2.234-0.453 4.141-1.594-0.75 2.375-2.891 3.641 1.953-0.219 3.781-1.047z" />
    </svg>
  );
}

TwitterIcon.propTypes = TwitterIconPropTypes;

TwitterIcon.defaultProps = {
  className: null
};

export default TwitterIcon;
