import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import getYear from 'date-fns/getYear';
import useTranslation from 'next-translate/useTranslation';
import { Hidden } from '@material-ui/core';

import LocalePickerV2 from '../../components-v2/LocalePicker';
import sidekickInit from '../../utils/sidekick/init';
import Image, { ImagePropTypes } from '../Image';
import ElementLink, { ElementLinkPropTypes } from '../ElementLink';
import ErrorBoundary from '../ErrorBoundary';
import SocialButtons from './SocialButtons';
import mockContent from './SocialButtons/SocialButtons.mock';
import styles from './GlobalFooter.module.scss';
import { useAppProvider } from '../../../AppProvider/AppProvider.tsx';
import PrivacyOptions from '../../components-v2/Icons/PrivacyOptions';

export const GlobalFooterPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  internalTitle: PropTypes.string.isRequired,
  localizationPicker: PropTypes.arrayOf(PropTypes.object).isRequired,
  localeDiscloserIcon: PropTypes.shape(ImagePropTypes).isRequired,
  localeDiscloserText: PropTypes.string.isRequired,
  // TODO replace with ElementLink
  logoUrl: PropTypes.string.isRequired,
  logo: PropTypes.shape(ImagePropTypes).isRequired,
  column1Header: PropTypes.string.isRequired,
  column1Links: PropTypes.arrayOf(PropTypes.shape(ElementLinkPropTypes)).isRequired,
  column2Header: PropTypes.string.isRequired,
  column2Links: PropTypes.arrayOf(PropTypes.shape(ElementLinkPropTypes)).isRequired,
  column3Header: PropTypes.string.isRequired,
  column3Links: PropTypes.arrayOf(PropTypes.shape(ElementLinkPropTypes)).isRequired,
  column4Header: PropTypes.string.isRequired,
  column4Links: PropTypes.arrayOf(PropTypes.shape(ElementLinkPropTypes)).isRequired,
  copyright: PropTypes.string.isRequired,
  utilityNavs: PropTypes.arrayOf(PropTypes.shape(ElementLinkPropTypes)).isRequired,
  includedLocales: PropTypes.arrayOf(PropTypes.string).isRequired
  // TODO update model
  // socialLink: PropTypes.object.isRequired
};

function GlobalFooter({
  _id,
  _contentTypeId,
  internalTitle,
  logo,
  logoUrl,
  column1Header,
  column1Links,
  column2Header,
  column2Links,
  column3Header,
  column3Links,
  column4Header,
  column4Links,
  localizationPicker,
  copyright,
  utilityNavs,
  includedLocales
}) {
  const { sidekicker } = sidekickInit({ _id, _contentTypeId, internalTitle });
  const { t } = useTranslation();
  const { contentGroup } = useAppProvider();

  const currentYear = useMemo(() => getYear(new Date()), []);

  const handleCookieDrawer = (e) => {
    e.preventDefault();
    window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
    const element = document.getElementById('osano-cm-drawer-toggle--category_OPT_OUT');
    if (element) {
      setTimeout(() => {
        element.scrollIntoView();
      }, 500);
    }
  };

  const createLinkClickHandler = (link) => () => {
    if (!window?.dataLayer) return;
    window.dataLayer.push({
      event: 'menu_navigation',
      menu_type: 'footer',
      menu_button: link?.linkText,
      link_url: link?.as ?? link.href,
      content_group: contentGroup
    });
  };

  return (
    <ErrorBoundary>
      <a
        aria-label="Skip Navigation Links and go to top content, press enter"
        className={cx('sr-only sr-only-focusable', styles.srOnly)}
        href="#maincontent">
        Skip Navigation Links and go to top content, press enter
      </a>
      <footer
        data-testid="GlobalFooter"
        id="global-footer"
        className={cx(styles.footer, 'd-print-none')}
        {...sidekicker('Global Footer')}>
        <div className="container-fluid container-xl">
          <div className="row">
            <h2 className="h2 col-12 text-center text-md-left">
              <a
                className={styles.logo}
                href={logoUrl}
                aria-label="Impossible Foods logo"
                {...sidekicker('Logo URL')}>
                {logo && (
                  <Image image={logo} testId="GlobalFooter-logo" {...sidekicker('Company Logo')} />
                )}
              </a>
            </h2>
          </div>
          <div className={cx('row', 'align-items-start')}>
            <div className={styles.localeSelectorAndLinks}>
              <Hidden implementation="css" smDown>
                <LocalePickerV2
                  languageOptions={localizationPicker}
                  includedLocales={includedLocales}
                />
              </Hidden>

              <nav className={styles.links}>
                <div>
                  <h3
                    className="h3"
                    data-testid="GlobalFooter-column1Header"
                    {...sidekicker('Column 1 Header')}>
                    {column1Header}
                  </h3>
                  <ul {...sidekicker('Column 1 Links')}>
                    {column1Links.map((item) => (
                      <li key={item.internalTitle} data-testid="GlobalFooter-column1Link">
                        <ElementLink
                          href={item.href}
                          as={item.as}
                          linkText={item.linkText}
                          icon={item.icon}
                          iconString={item.iconString}
                          target={item.target}
                          isModal={item.isModal}
                          download={item.download}
                          trackingId={item.trackingId}
                          style={item.style}
                          onClick={createLinkClickHandler(item)}
                          {...sidekickInit(item).sidekicker('Element Link')}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h3
                    className="h3"
                    data-testid="GlobalFooter-column2Header"
                    {...sidekicker('Column 2 Header')}>
                    {column2Header}
                  </h3>
                  <ul {...sidekicker('Column 2 Links')}>
                    {column2Links.map((item) => (
                      <li key={item.internalTitle} data-testid="GlobalFooter-column2Link">
                        <ElementLink
                          href={item.href}
                          as={item.as}
                          linkText={item.linkText}
                          icon={item.icon}
                          iconString={item.iconString}
                          target={item.target}
                          isModal={item.isModal}
                          download={item.download}
                          trackingId={item.trackingId}
                          style={item.style}
                          onClick={createLinkClickHandler(item)}
                          {...sidekickInit(item).sidekicker('Element Link')}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h3
                    className="h3"
                    data-testid="GlobalFooter-column3Header"
                    {...sidekicker('Column 3 Header')}>
                    {column3Header}
                  </h3>
                  <ul {...sidekicker('Column 3 Links')}>
                    {column3Links.map((item) => (
                      <li key={item.internalTitle} data-testid="GlobalFooter-column3Link">
                        <ElementLink
                          href={item.href}
                          as={item.as}
                          linkText={item.linkText}
                          icon={item.icon}
                          iconString={item.iconString}
                          target={item.target}
                          isModal={item.isModal}
                          download={item.download}
                          trackingId={item.trackingId}
                          style={item.style}
                          onClick={createLinkClickHandler(item)}
                          {...sidekickInit(item).sidekicker('Element Link')}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h3
                    className="h3"
                    data-testid="GlobalFooter-column4Header"
                    {...sidekicker('Column 4 Header')}>
                    {column4Header}
                  </h3>
                  <ul {...sidekicker('Column 4 Links')}>
                    {column4Links.map((item) => (
                      <li key={item.internalTitle} data-testid="GlobalFooter-column4Link">
                        <ElementLink
                          href={item.href}
                          as={item.as}
                          linkText={item.linkText}
                          icon={item.icon}
                          iconString={item.iconString}
                          target={item.target}
                          isModal={item.isModal}
                          download={item.download}
                          trackingId={item.trackingId}
                          style={item.style}
                          onClick={createLinkClickHandler(item)}
                          {...sidekickInit(item).sidekicker('Element Link')}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </nav>
              <Hidden smUp implementation="css">
                <LocalePickerV2
                  languageOptions={localizationPicker}
                  includedLocales={includedLocales}
                />
              </Hidden>
            </div>
          </div>

          <div className="d-md-flex flex-column flex-xl-row justify-content-between">
            <SocialButtons socialLinks={mockContent} />
            <div className={styles.terms}>
              {utilityNavs && (
                <div className="mb-3">
                  <ul
                    className="d-flex flex-column flex-wrap flex-md-row  mb-3 mb-md-1"
                    data-testid="GlobalFooter-utilityNavs"
                    {...sidekicker('Utility Nav Links')}>
                    {utilityNavs.map((item) => (
                      <li key={item.internalTitle}>
                        <ElementLink
                          href={item.href}
                          as={item.as}
                          linkText={item.linkText}
                          icon={item.icon}
                          iconString={item.iconString}
                          target={item.target}
                          isModal={item.isModal}
                          download={item.download}
                          trackingId={item.trackingId}
                          style={item.style}
                          onClick={createLinkClickHandler(item)}
                          {...sidekickInit(item).sidekicker('Element Link')}
                        />
                      </li>
                    ))}
                  </ul>
                  <p className="mb-3 mb-md-1 text-xl-right">
                    <ElementLink
                      aria-label="Opens a dialog with the Cookie Settings"
                      onClick={(e) => handleCookieDrawer(e)}
                      tabIndex="0"
                      className={styles.privacyOptions}>
                      {t('common:doNotSellMyInfo')}
                      <PrivacyOptions />
                    </ElementLink>
                  </p>
                  <div className="mb-3 mb-md-1 d-flex justify-content-end">
                    <div id="google-recaptcha-container" className={styles.recaptchaContainer} />
                  </div>
                </div>
              )}
              {copyright && (
                <p {...sidekicker('Copyright Text')}>
                  {copyright.replace('{{CURRENT_YEAR}}', String(currentYear))}
                </p>
              )}
            </div>
          </div>
        </div>
      </footer>
    </ErrorBoundary>
  );
}

GlobalFooter.propTypes = GlobalFooterPropTypes;

export default GlobalFooter;
