import React from 'react';
import PropTypes from 'prop-types';

export const MediumIconPropTypes = {
  className: PropTypes.string
};

function MediumIcon({ className }) {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      className={className}
      version="1.1"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <title>Medium</title>
      <path
        d="M0,0v32h32V0H0z M26.6,7.6l-1.7,1.6c-0.1,0.1-0.2,0.3-0.2,0.5v12.1c0,0.2,0,0.4,0.2,0.5l1.7,1.6v0.4h-8.4v-0.4l1.7-1.7
                C20,22.1,20,22,20,21.8V12l-4.8,12.3h-0.7L8.9,12v8.2c0,0.3,0.1,0.7,0.3,0.9l2.3,2.7v0.4H5.1v-0.4l2.3-2.7c0.2-0.2,0.3-0.6,0.3-0.9
                v-9.5c0-0.3-0.1-0.5-0.3-0.7l-2-2.4V7.2h6.2l4.8,10.6l4.2-10.6h5.9V7.6z"
      />
    </svg>
  );
}

MediumIcon.propTypes = MediumIconPropTypes;

MediumIcon.defaultProps = {
  className: null
};

export default MediumIcon;
