import React from 'react';
import PropTypes from 'prop-types';

export const GlobePropTypes = {
  className: PropTypes.string
};

function Globe({ className }) {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      xmlns="http://www.w3.org/2000/svg"
      width="27.652"
      height="27.645"
      viewBox="0 0 27.652 27.645"
      className={className}
    >
      <path
        data-name="Icon ionic-ios-globe"
        d="M17.2,3.375h-.047a13.823,13.823,0,0,0,.007,27.645H17.2A13.823,13.823,0,1,0,17.2,3.375ZM29.173,16.267H24.016a26.17,26.17,0,0,0-.651-5.064,20.245,20.245,0,0,0,3.3-1.4A11.906,11.906,0,0,1,29.173,16.267Zm-12.906,0H12.16a23.621,23.621,0,0,1,.585-4.592,20.218,20.218,0,0,0,3.522.472Zm0,1.861v4.114a20.3,20.3,0,0,0-3.522.472,23.618,23.618,0,0,1-.585-4.585Zm1.861,0H22.2a23.482,23.482,0,0,1-.585,4.579,19.836,19.836,0,0,0-3.489-.465Zm0-1.861v-4.12a20.3,20.3,0,0,0,3.489-.465,23.551,23.551,0,0,1,.585,4.585ZM25.4,8.432a18.462,18.462,0,0,1-2.525,1.03A13.721,13.721,0,0,0,21.112,5.84,12.025,12.025,0,0,1,25.4,8.432Zm-4.28,1.509a18.7,18.7,0,0,1-2.99.4V5.462C19.258,6.073,20.361,7.714,21.118,9.941ZM16.267,5.435v4.9a18.38,18.38,0,0,1-3.024-.405C14.014,7.681,15.131,6.04,16.267,5.435Zm-3.03.425a13.83,13.83,0,0,0-1.748,3.6A18.877,18.877,0,0,1,9,8.432,11.846,11.846,0,0,1,13.237,5.86ZM7.728,9.814A19.843,19.843,0,0,0,11,11.2a25.115,25.115,0,0,0-.651,5.064H5.229A11.845,11.845,0,0,1,7.728,9.814ZM5.222,18.128h5.117a26.1,26.1,0,0,0,.651,5.064,21.055,21.055,0,0,0-3.27,1.382A11.908,11.908,0,0,1,5.222,18.128ZM8.99,25.963a18.6,18.6,0,0,1,2.5-1.023,13.879,13.879,0,0,0,1.748,3.6A12.14,12.14,0,0,1,8.99,25.963Zm4.253-1.5a18.476,18.476,0,0,1,3.024-.405v4.9C15.124,28.355,14.014,26.707,13.244,24.461Zm4.884,4.472V24.049a18.7,18.7,0,0,1,2.99.4C20.361,26.681,19.258,28.322,18.128,28.934Zm2.99-.379a13.721,13.721,0,0,0,1.761-3.622A18.157,18.157,0,0,1,25.4,25.97,12.163,12.163,0,0,1,21.118,28.555Zm5.549-3.974a20.245,20.245,0,0,0-3.3-1.4,26.034,26.034,0,0,0,.651-5.057h5.157A11.854,11.854,0,0,1,26.667,24.581Z"
        transform="translate(-3.375 -3.375)"
        fill="#18181a"
      />
    </svg>
  );
}

Globe.propTypes = GlobePropTypes;

Globe.defaultProps = {
  className: null
};

export default Globe;
