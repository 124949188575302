import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useCookies } from 'react-cookie';

import getPath, { getLocale } from '../../utils/getPath';
import getLang from '../../utils/getLang';
import { getCookieConfig } from '../../utils/constants';
import LocalePickerPresentationComponent from './components/LocalePicker';

function LocalePicker({ languageOptions, includedLocales }) {
  const router = useRouter();
  const { lang } = useTranslation();
  const [, setCookie] = useCookies(['if_locale']);

  const [currentLocale, setCurrentLocale] = useState('');

  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    const locale = getLang(lang);
    setCurrentLocale(locale);
  }, [lang]);

  const changeLocale = (locale) => {
    const config = getCookieConfig();
    setCookie('if_locale', getPath({ path: '/', locale }), {
      ...config,
      maxAge: process.env.NEXT_PUBLIC_LOCALE_COOKIE_AGE
    });
    const mappedLocale = getLocale(locale);
    if (includedLocales?.includes(locale)) {
      router.push(router.asPath, router.asPath, { locale: mappedLocale });
    } else {
      router.push('/', '/', { locale: mappedLocale });
    }
  };

  return (
    <LocalePickerPresentationComponent
      languageOptions={languageOptions}
      currentLocale={currentLocale}
      isDesktop={isDesktop}
      changeLocale={changeLocale}
    />
  );
}

LocalePicker.propTypes = {
  languageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      regionName: PropTypes.string.isRequired,
      countries: PropTypes.arrayOf(
        PropTypes.shape({
          country: PropTypes.string.isRequired,
          locales: PropTypes.arrayOf(
            PropTypes.shape({
              locale: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired
            })
          ).isRequired
        })
      )
    })
  ).isRequired,
  includedLocales: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default LocalePicker;
