import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import flatMap from 'lodash/flatMap';
import useTranslation from 'next-translate/useTranslation';

import GlobeIcon from '../../../../assets/icons/Globe';
import LocalePickerDrawer from '../LocalePickerDrawer';
import LocalePickerModal from '../LocalePickerModal';

import useStyles from './LocalePicker.styles';

function LocalePicker({ languageOptions, currentLocale, isDesktop, changeLocale }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [localePickerIsOpen, setLocalePickerIsOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedLocale, setSelectedLocale] = useState('');

  const { localeCountryMap, localesMap, countryLocalesMap } = useMemo(() => {
    const allCountries = flatMap(languageOptions, (languageOption) => languageOption.countries);

    const countries = {};
    const locales = {};
    const countryLocales = {};

    allCountries?.forEach((country) => {
      if (country?.country) {
        countryLocales[country.country] = country.locales;

        country.locales?.forEach((locale) => {
          countries[locale.locale] = country.country;
          locales[locale.locale] = locale.title;
        });
      }
    });

    return { localeCountryMap: countries, localesMap: locales, countryLocalesMap: countryLocales };
  }, [languageOptions]);

  useEffect(() => {
    if (currentLocale in localeCountryMap) {
      setSelectedCountry(localeCountryMap[currentLocale]);
      setSelectedLocale(currentLocale);
    }
  }, [currentLocale]);

  const openLocalePicker = () => {
    setLocalePickerIsOpen(true);
  };

  const closeLocalePicker = () => {
    setLocalePickerIsOpen(false);
  };

  const changeSelectedLocale = (country, locale) => {
    document.documentElement.style.scrollBehavior = 'unset';
    setTimeout(() => {
      // TODO: see if we can hook locale changed, and scrolled to top event from next.js
      document.documentElement.style.scrollBehavior = 'smooth';
    }, 2000);
    changeLocale(locale);
    setLocalePickerIsOpen(false);
    setSelectedCountry(country);
    setSelectedLocale(locale);
  };

  return (
    <>
      <Box>
        <Button
          onClick={openLocalePicker}
          type="button"
          fullWidth={!isDesktop}
          className={classes.toggleButton}
          aria-label={`${t('common:countrySelectorSelectedLanguage')}: ${selectedCountry}, ${
            localesMap[selectedLocale] || ''
          }. ${t('common:countrySelectorOpenModal')}.`}
        >
          <Box
            display="flex"
            flexDirection={isDesktop ? 'column' : 'row'}
            justifyContent={isDesktop ? 'flex-start' : 'space-between'}
            alignItems={isDesktop ? 'flex-start' : 'center'}
            className={classes.toggleButtonInner}
          >
            <Box display="flex" alignItems="flex-start">
              <GlobeIcon className={classes.globeIcon} />
              <Typography variant="h3" className={`h3 ${classes.buttonCountry}`}>
                {selectedCountry}
              </Typography>
            </Box>
            {selectedLocale && (
              <Typography variant="body1" className={classes.buttonLocale}>
                {localesMap[selectedLocale]}
              </Typography>
            )}
          </Box>
        </Button>
      </Box>
      {isDesktop ? (
        <LocalePickerDrawer
          languageOptions={languageOptions}
          currentCountry={selectedCountry}
          currentLocale={selectedLocale}
          open={localePickerIsOpen}
          countryLocalesMap={countryLocalesMap}
          onClose={closeLocalePicker}
          onSelectLocale={changeSelectedLocale}
        />
      ) : (
        <LocalePickerModal
          languageOptions={languageOptions}
          currentCountry={selectedCountry}
          currentLocale={selectedLocale}
          open={localePickerIsOpen}
          countryLocalesMap={countryLocalesMap}
          onClose={closeLocalePicker}
          onSelectLocale={changeSelectedLocale}
        />
      )}
    </>
  );
}

LocalePicker.propTypes = {
  languageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      regionName: PropTypes.string.isRequired,
      countries: PropTypes.arrayOf(
        PropTypes.shape({
          country: PropTypes.string.isRequired,
          locales: PropTypes.arrayOf(
            PropTypes.shape({
              locale: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired
            })
          ).isRequired
        })
      )
    })
  ).isRequired,
  currentLocale: PropTypes.string,
  isDesktop: PropTypes.bool.isRequired,
  changeLocale: PropTypes.func.isRequired
};

LocalePicker.defaultProps = {
  currentLocale: ''
};

export default LocalePicker;
