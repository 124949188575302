import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: 25,
    height: 25,
    minWidth: 25,
    fontFamily: theme.typography.body1.fontFamily,
    fontWeight: 700,
    fontSize: 25,
    lineHeight: 1
  },
  title: {
    // letterSpacing: 0,
    textTransform: 'uppercase',
    marginLeft: theme.spacing(1)
  },
  countrySelect: {
    'padding': theme.spacing(1, 2),
    'border': `1px solid ${theme.palette.extra.black}`,
    'borderRadius': 6,

    '& select': {
      'padding': theme.spacing(1),

      '&:focus': {
        background: 'transparent',
        outline: '0.0625rem solid #ffcd00'
      }
    },

    '&::before': {
      display: 'none'
    },

    '&::after': {
      display: 'none'
    },

    '& svg': {
      color: theme.palette.extra.black
    }
  },
  localeButton: {
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: 12,
    fontWeight: 700,
    textTransform: 'capitalize',
    padding: theme.spacing(0.2, 1),
    minWidth: 0,
    borderRadius: 0
  },
  selectedLocaleButton: {
    color: theme.palette.extra.blue,
    borderBottom: `3px solid ${theme.palette.extra.blue}`
  },
  confirmTitle: {
    // letterSpacing: 0,
    textTransform: 'uppercase',
    marginBottom: theme.spacing(2)
  },
  submitButton: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(1.5, 3)
  }
}));
