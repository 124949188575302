import React from 'react';
import PropTypes from 'prop-types';

export const YoutubeIconPropTypes = {
  className: PropTypes.string
};

function YoutubeIcon({ className }) {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      className={className}
      version="1.1"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <title>Youtube</title>
      <path d="M16 4.672q7.25 0 9.531 0.203 3.297 0.281 4.625 1.328 1.234 0.969 1.594 3.203 0.25 1.594 0.25 6.125v0.469q0 4.703-0.203 6.266-0.328 2.375-1.484 3.391-1.234 1.109-4.344 1.422-2.313 0.25-9.313 0.25h-0.656q-7.25 0-9.531-0.203-3.297-0.281-4.625-1.328-1.234-0.969-1.594-3.203-0.25-1.594-0.25-6.125v-0.469q0-4.703 0.203-6.266 0.328-2.375 1.484-3.391 1.234-1.109 4.344-1.422 2.313-0.25 9.313-0.25h0.656zM12 22l10-6-10-6v12z" />
    </svg>
  );
}

YoutubeIcon.propTypes = YoutubeIconPropTypes;

YoutubeIcon.defaultProps = {
  className: null
};

export default YoutubeIcon;
