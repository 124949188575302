export default [
  {
    url: 'https://twitter.com/ImpossibleFoods',
    type: 'twitter'
  },
  {
    url: 'https://www.facebook.com/ImpossibleFoods/',
    type: 'facebook'
  },
  {
    url: 'https://www.instagram.com/impossible_foods',
    type: 'instagram'
  },
  {
    url: 'https://www.youtube.com/channel/UCDOAr7cjp7c__9D_PIF3hsA',
    type: 'youtube'
  },
  // {
  //   url: 'https://medium.com/impossible-foods',
  //   type: 'medium'
  // },
  {
    url: 'https://www.linkedin.com/company/impossible-foods-inc./',
    type: 'linkedin'
  },
  {
    url: 'https://open.spotify.com/user/prsuztlf5uln58u9oj7x7kaff?si=smn-c1YFTrWzfECLTRpuTA',
    type: 'spotify'
  }
];
