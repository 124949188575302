import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  toggleButton: {
    border: '1px solid #707077',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),

    [theme.breakpoints.up('md')]: {
      padding: 0,
      marginBottom: 0,
      border: 'none'
    }
  },
  toggleButtonInner: {
    width: '100%'
  },
  buttonCountry: {
    fontSize: 26,
    lineHeight: '2.3125rem',
    color: theme.palette.extra.white,
    textAlign: 'left',
    marginLeft: theme.spacing(1)
  },
  buttonLocale: {
    display: 'inline-block',
    fontSize: 13,
    color: theme.palette.extra.white,
    textTransform: 'capitalize',
    borderBottom: `3px solid ${theme.palette.extra.white}`,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      marginLeft: `calc(20px + ${theme.spacing(1)}px)`,
      marginTop: '11px',
      marginRight: 0
    }
  },

  globeIcon: {
    'width': 20,
    'height': 20,
    'marginTop': theme.spacing(1),
    '& path': {
      fill: theme.palette.extra.white
    }
  }
}));
