import React from 'react';
import PropTypes from 'prop-types';

export const InstagramIconPropTypes = {
  className: PropTypes.string
};

function InstagramIcon({ className }) {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      className={className}
      version="1.1"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <title>Instagram</title>
      <path d="M27.328 0q1.938 0 3.305 1.367t1.367 3.305v22.656q0 1.938-1.367 3.305t-3.305 1.367h-22.656q-1.938 0-3.305-1.367t-1.367-3.305v-22.656q0-1.938 1.367-3.305t3.305-1.367h22.656zM16 10q-2.484 0-4.242 1.758t-1.758 4.242 1.758 4.242 4.242 1.758 4.242-1.758 1.758-4.242-1.758-4.242-4.242-1.758zM28.672 28v-14h-3.547q0.203 0.922 0.203 2 0 2.531-1.25 4.68t-3.398 3.398-4.68 1.25-4.68-1.25-3.398-3.398-1.25-4.68q0-1.078 0.203-2h-3.547v14q0 0.281 0.195 0.477t0.477 0.195h24q0.281 0 0.477-0.195t0.195-0.477zM28.672 8v-4q0-0.281-0.195-0.477t-0.477-0.195h-4q-0.281 0-0.477 0.195t-0.195 0.477v4q0 0.281 0.195 0.477t0.477 0.195h4q0.281 0 0.477-0.195t0.195-0.477z" />
    </svg>
  );
}

InstagramIcon.propTypes = InstagramIconPropTypes;

InstagramIcon.defaultProps = {
  className: null
};

export default InstagramIcon;
