import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  drawer: {
    zIndex: '10001!important'
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: 25,
    height: 25,
    minWidth: 25,
    fontFamily: theme.typography.body1.fontFamily,
    fontWeight: 700,
    fontSize: 25,
    lineHeight: 1
  },
  title: {
    fontSize: 33,
    // letterSpacing: 0,
    textTransform: 'uppercase',
    marginLeft: theme.spacing(1)
  },
  region: {
    fontSize: 30,
    // letterSpacing: 0,
    textTransform: 'uppercase',
    marginBottom: theme.spacing(2)
  },
  country: {
    textTransform: 'uppercase',
    fontSize: '21px',
    padding: theme.spacing(0.8),
    display: 'inline-block'
  },
  selectedCountry: {
    background: theme.palette.extra.blue,
    color: theme.palette.extra.white
  },
  localeButton: {
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: 12,
    fontWeight: 700,
    textTransform: 'capitalize',
    padding: theme.spacing(0.2, 1),
    minWidth: 0,
    borderRadius: 0
  },
  selectedLocaleButton: {
    color: theme.palette.extra.blue,
    borderBottom: `3px solid ${theme.palette.extra.blue}`
  },
  confirmationDialogTitle: {
    // fontSize: 33,
    // letterSpacing: 0,
    textTransform: 'uppercase',
    marginBottom: theme.spacing(2)
  },
  confirmationDialogCountry: {
    // letterSpacing: 0,
    marginBottom: theme.spacing(2)
  },
  confirmationDialogSubmitButton: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(1.5, 3)
  }
}));
