// Origin: PERF_METRICS if-website/src/lib/utils/constants.js
export const METRICS = {
  DL_READY: 'data-layer-ready',
  PS_EVENT: 'page-speed',
  UD_EVENT: 'user-driven',
  TTI: 'time_to_interactive',
  FMP: 'first_meaningful_paint',
  FCP: 'first_contentful_paint',
  FPC: 'first_pin_click',
  FCC: 'first_card_click',
  FLV: 'first_list_view_click',
  FSE: 'first_search_executed',
  STE: 'search_time_elapsed'
};

// Cookie config
export const cookieConfig = {
  path: '/',
  maxAge: 31536000,
  secure: process.env.NODE_ENV === 'production' && true
};

export const getCookieConfig = () => {
  const isLocal = global.location && global.location.host.includes('localhost');
  const getDomain = () => {
    if (isLocal) return '';
    return global.location ? `.${global.location.host}` : '.impossiblefoods.com';
  };
  return { ...cookieConfig, getDomain };
};

export const DEFAULT_EMAIL_FROM = 'hello@impossiblefoods.com';
export const LOCATION_ID_FIELD = 'Google Place ID';
export const osanoAllowedLocales = ['gb-en'];
