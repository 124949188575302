import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import GlobeIcon from '../../../../assets/icons/Globe';
import useStyles from './LocalePickerDrawer.styles';

function LocalePickerDrawer({
  languageOptions,
  currentCountry,
  currentLocale,
  open,
  countryLocalesMap,
  onClose,
  onSelectLocale
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(false);
  const [selectedLocale, setSelectedLocale] = useState(false);

  const selectLocale = (country, locale) => () => {
    setSelectedCountry(country);
    setSelectedLocale(locale);

    setConfirmationDialogIsOpen(true);
  };

  const closeConfirmationDialog = () => {
    setConfirmationDialogIsOpen(false);
  };

  const changeLocaleSelection = (locale) => () => {
    setSelectedLocale(locale);
  };

  const confirmLocaleSelection = () => {
    onSelectLocale(selectedCountry, selectedLocale);

    closeConfirmationDialog();
  };

  const focusTitleWhenAttached = (element) => {
    if (!element || !open) return;
    element.focus();
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open && !confirmationDialogIsOpen}
        onClose={onClose}
        className={classes.drawer}
      >
        <Box
          role="dialog"
          aria-label={t('common:localePickerAriaLabel')}
          aria-modal="true"
          padding={5}
        >
          <Button
            type="button"
            className={classes.closeButton}
            onClick={onClose}
            aria-label={t('common:countrySelectorCloseModalAriaLabel')}
          >
            x
          </Button>
          <Box aria-live="polite" aria-atomic="true" display="flex" alignItems="center" mb={4}>
            <GlobeIcon />
            <Typography variant="h4" className={`h4 ${classes.title}`} ref={focusTitleWhenAttached}>
              {t('common:countrySelectorTitle')}
            </Typography>
          </Box>
          {languageOptions?.map((languageOption) => (
            <Box key={languageOption.regionName}>
              <Typography variant="h5" className={`h5 ${classes.region}`}>
                {languageOption.regionName}
              </Typography>
              {languageOption.countries?.map((country) => (
                <Box marginLeft={-0.8} marginBottom={2} key={country.country}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography
                        variant="h6"
                        className={cx('h6', classes.country, {
                          [classes.selectedCountry]: currentCountry === country.country
                        })}
                      >
                        {country.country}
                      </Typography>
                    </Grid>
                    {country.locales?.map((locale) => (
                      <Grid item xs={3} key={locale.locale}>
                        <Button
                          type="button"
                          aria-selected={currentLocale === locale.locale ? 'true' : 'false'}
                          aria-label={
                            currentLocale === locale.locale
                              ? `Selected ${locale.title} from ${country.country}`
                              : `Press enter to select ${locale.title} from ${country.country}`
                          }
                          onClick={selectLocale(country.country, locale.locale)}
                          className={cx(classes.localeButton, {
                            [classes.selectedLocaleButton]: currentLocale === locale.locale
                          })}
                        >
                          <span lang={locale.locale}>{locale.title}</span>
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Drawer>
      {confirmationDialogIsOpen && (
        <Dialog
          role="dialog"
          aria-live="polite"
          aria-label={t('common:countrySelectorConfirmationModalAriaLabel')}
          aria-modal="true"
          open={open}
          onClose={closeConfirmationDialog}
        >
          <Button
            type="button"
            className={classes.closeButton}
            onClick={closeConfirmationDialog}
            aria-label={t('common:countrySelectorCloseConfirmationModalAriaLabel')}
          >
            x
          </Button>
          <form onSubmit={confirmLocaleSelection}>
            <Box
              paddingTop={6}
              paddingLeft={2}
              paddingRight={2}
              paddingBottom={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              {selectedCountry && selectedCountry in countryLocalesMap && (
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" className={`h6 ${classes.confirmationDialogTitle}`}>
                    {t('common:countrySelectorChooseLanguage')}
                  </Typography>
                  <Typography variant="h6" className={`h6 ${classes.confirmationDialogCountry}`}>
                    {selectedCountry}
                  </Typography>
                  <Box display="flex" justifyContent="space-between">
                    {countryLocalesMap[selectedCountry].map((locale) => (
                      <Button
                        aria-current={selectedLocale === locale.locale ? 'true' : 'false'}
                        type="button"
                        aria-label={`Select ${locale.title} from ${selectedCountry}`}
                        onClick={changeLocaleSelection(locale.locale)}
                        className={cx(classes.localeButton, {
                          [classes.selectedLocaleButton]: selectedLocale === locale.locale
                        })}
                      >
                        <span lang={locale.locale}>{locale.title}</span>
                      </Button>
                    ))}
                  </Box>
                  <Button
                    aria-label={t('common:countrySelectorContinueButtonAriaLabel')}
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.confirmationDialogSubmitButton}
                  >
                    {t('common:countrySelectorContinueButton')}
                  </Button>
                </Box>
              )}
            </Box>
          </form>
        </Dialog>
      )}
    </>
  );
}

LocalePickerDrawer.propTypes = {
  languageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      regionName: PropTypes.string.isRequired,
      countries: PropTypes.arrayOf(
        PropTypes.shape({
          country: PropTypes.string.isRequired,
          locales: PropTypes.arrayOf(
            PropTypes.shape({
              locale: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired
            })
          ).isRequired
        })
      )
    })
  ).isRequired,
  countryLocalesMap: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        locale: PropTypes.string.isRequired
      })
    )
  ).isRequired,
  currentCountry: PropTypes.string,
  currentLocale: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelectLocale: PropTypes.func.isRequired
};

LocalePickerDrawer.defaultProps = {
  currentCountry: '',
  currentLocale: ''
};

export default LocalePickerDrawer;
