/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './SocialButtons.module.scss';
import TwitterIcon from '../../../assets/icons/Twitter';
import FacebookIcon from '../../../assets/icons/Facebook';
import InstagramIcon from '../../../assets/icons/Instagram';
import YoutubeIcon from '../../../assets/icons/Youtube';
import MediumIcon from '../../../assets/icons/Medium';
import LinkedinIcon from '../../../assets/icons/Linkedin';
import SpotifyIcon from '../../../assets/icons/Spotify';
import { useAppProvider } from '../../../../AppProvider/AppProvider.tsx';

const icons = {
  twitter: TwitterIcon,
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  youtube: YoutubeIcon,
  medium: MediumIcon,
  linkedin: LinkedinIcon,
  spotify: SpotifyIcon
};

const getIcon = (type) => {
  const Icon = icons[type];
  return <Icon />;
};

const SocialButtonPropTypes = {
  url: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export const SocialButtonsPropTypes = {
  socialLinks: PropTypes.arrayOf(PropTypes.shape(SocialButtonPropTypes)).isRequired
};

function SocialButtons({ socialLinks } = {}) {
  const { contentGroup } = useAppProvider();

  const handleClick = () => {
    window.dataLayer = window?.dataLayer || [];
    window.dataLayer.push({
      event: 'visit_social',
      content_group: contentGroup
    });
  };

  return (
    <ul className={styles.socials}>
      {socialLinks.map((link) => (
        <li className={styles.iconWrap} key={link.type}>
          <a
            href={link.url}
            rel="noopener noreferrer"
            aria-label={`visit our ${link.type}`}
            target="_blank"
            onClick={handleClick}>
            {getIcon(link.type)}
          </a>
        </li>
      ))}
    </ul>
  );
}

SocialButtons.propTypes = SocialButtonsPropTypes;

SocialButtons.defaultProps = {};

export default SocialButtons;
