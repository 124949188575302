import React from 'react';
import PropTypes from 'prop-types';

export const SpotifyIconPropTypes = {
  className: PropTypes.string
};

function SpotifyIcon({ className }) {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      className={className}
      version="1.1"
      width="32"
      height="32"
      viewBox="0 0 167.49 167.49"
    >
      <title>Spotify</title>
      <path
        d="M83.72 0a83.75 83.75 0 1083.77 83.72A83.75 83.75 0 0083.72 0zm38.4 120.79a5.22 5.22 0 01-7.18 1.74c-19.66-12-44.41-14.74-73.56-8.08a5.22 5.22 0 11-2.33-10.17c31.9-7.3 59.27-4.16 81.34 9.33a5.22 5.22 0 011.76 7.18zm10.25-22.8a6.54 6.54 0 01-9 2.15C100.86 86.3 66.55 82.3 39.92 90.38a6.531 6.531 0 11-3.79-12.5c30.41-9.22 68.22-4.75 94.07 11.13a6.54 6.54 0 012.2 8.98zm.88-23.75c-27-16-71.52-17.5-97.29-9.68a7.836 7.836 0 11-4.54-15c29.58-9 78.75-7.25 109.83 11.2a7.833 7.833 0 01-8 13.47z"
        fill="#fff"
      />
    </svg>
  );
}

SpotifyIcon.propTypes = SpotifyIconPropTypes;

SpotifyIcon.defaultProps = {
  className: null
};

export default SpotifyIcon;
